var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          ref: "topToolbar",
          staticClass: "d-flex justify-space-between px-5 py-2"
        },
        [
          _c("div", { staticClass: "caption" }, [
            _vm.isOnline
              ? _c("span", [
                  _vm._v(
                    " The Form Submissions Shown Below Have Been Cleared And Are Ready To Be Uploaded. "
                  )
                ])
              : _c("span", { staticClass: "red--text" }, [
                  _vm._v(" Device offline. Must be online to upload tickets. ")
                ])
          ]),
          _c(
            "v-btn",
            {
              staticClass: "button",
              attrs: {
                disabled:
                  _vm.formSubmissions.length === 0 ||
                  _vm.selectedFormSubmissions.length === 0,
                dark: !(
                  _vm.formSubmissions.length === 0 ||
                  _vm.selectedFormSubmissions.length === 0
                )
              },
              on: { click: _vm.submit }
            },
            [_vm._v(" Upload Form Submissions ")]
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "ma-0 pa-0" },
        [
          _c("v-card-text", { staticClass: "ma-0 pa-0" }, [
            _c(
              "div",
              { staticClass: "d-flex align-center px-4" },
              [
                _c("v-checkbox", {
                  attrs: {
                    indeterminate:
                      _vm.selectedFormSubmissions.length > 0 &&
                      _vm.selectedFormSubmissions.length <
                        _vm.formSubmissions.length
                  },
                  model: {
                    value: _vm.selectAllFormSubmissions,
                    callback: function($$v) {
                      _vm.selectAllFormSubmissions = $$v
                    },
                    expression: "selectAllFormSubmissions"
                  }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm.formSubmissions.length > 0
        ? _c(
            "section",
            {
              staticClass: "overflow-y-auto",
              style: { height: _vm.tableHeight + "px" }
            },
            _vm._l(_vm.formSubmissions, function(item, index) {
              return _c(
                "v-card",
                {
                  key: item.formResultId + "-" + index,
                  staticClass: "mb-3",
                  attrs: { rounded: false }
                },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "d-flex justify-space-between",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.$emit("row-clicked", item)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              "input-value": true,
                              value: _vm.selected[item.formResultId]
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                              },
                              change: function($event) {
                                _vm.$set(
                                  _vm.selected,
                                  item.formResultId,
                                  Boolean($event)
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "v-list",
                    { staticClass: "mt-n8", attrs: { dense: "" } },
                    [
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.$emit("row-clicked", item)
                            }
                          }
                        },
                        [
                          _c("v-list-item-content", [
                            _c("div", { staticClass: "d-flex" }, [
                              _c(
                                "div",
                                { staticClass: "caption field-names mr-2" },
                                [_vm._v("REF FIELD")]
                              ),
                              _c("div", { staticClass: "field-value" }, [
                                _vm._v(_vm._s(_vm.getSiteName(item)))
                              ])
                            ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.$emit("row-clicked", item)
                            }
                          }
                        },
                        [
                          _c("v-list-item-content", [
                            _c("div", { staticClass: "d-flex" }, [
                              _c(
                                "div",
                                { staticClass: "caption field-names mr-2" },
                                [_vm._v("FORM")]
                              ),
                              _c("div", { staticClass: "field-value" }, [
                                _vm._v(_vm._s(item.formTitle))
                              ])
                            ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.$emit("row-clicked", item)
                            }
                          }
                        },
                        [
                          _c("v-list-item-content", [
                            _c("div", { staticClass: "d-flex" }, [
                              _c(
                                "div",
                                { staticClass: "caption field-names mr-2" },
                                [_vm._v("SUBMITTED BY")]
                              ),
                              _c("div", { staticClass: "field-value" }, [
                                _vm._v(_vm._s(item.submittedBy))
                              ])
                            ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.$emit("row-clicked", item)
                            }
                          }
                        },
                        [
                          _c("v-list-item-content", [
                            _c("div", { staticClass: "d-flex" }, [
                              _c(
                                "div",
                                { staticClass: "caption field-names mr-2" },
                                [_vm._v("UPDATED ON")]
                              ),
                              _c("div", { staticClass: "field-value" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("formatFullDate")(item.updatedOn)
                                    ) +
                                    " "
                                )
                              ])
                            ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.$emit("row-clicked", item)
                            }
                          }
                        },
                        [
                          _c("v-list-item-content", [
                            _c("div", { staticClass: "d-flex" }, [
                              _c(
                                "div",
                                { staticClass: "caption field-names mr-2" },
                                [_vm._v("LAYER")]
                              ),
                              _c("div", { staticClass: "field-value" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.getMapServiceName(item)) +
                                    " "
                                )
                              ])
                            ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.$emit("row-clicked", item)
                            }
                          }
                        },
                        [
                          _c("v-list-item-content", [
                            _c("div", { staticClass: "d-flex" }, [
                              _c(
                                "div",
                                { staticClass: "caption field-names mr-2" },
                                [_vm._v("OBJECT ID")]
                              ),
                              _c("div", { staticClass: "field-value" }, [
                                _vm._v(" " + _vm._s(item.featureId) + " ")
                              ])
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        : _c(
            "section",
            [
              _c(
                "v-card",
                [_c("v-card-text", [_vm._v(" No data available ")])],
                1
              )
            ],
            1
          ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600px" },
          model: {
            value: _vm.showSubmittingDialog,
            callback: function($$v) {
              _vm.showSubmittingDialog = $$v
            },
            expression: "showSubmittingDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "12" }
                            },
                            [
                              _vm._v(
                                " Submitting " +
                                  _vm._s(_vm.numUnsubmittedTickets) +
                                  " of " +
                                  _vm._s(_vm.selected.length) +
                                  " Forms "
                              )
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "12" }
                            },
                            [
                              _c("v-progress-circular", {
                                attrs: { indeterminate: "", color: "#673AB7" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.showSubmittingDialog = false
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600px", close: "" },
          model: {
            value: _vm.showErrorDialog,
            callback: function($$v) {
              _vm.showErrorDialog = $$v
            },
            expression: "showErrorDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v(" Error ")]),
              _c("v-card-text", [_vm._v(" " + _vm._s(_vm.errorMsg) + " ")])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }