import { db } from "@/mixins/utilisync-db";

const getUtiliSyncLayerFeatureSiteName = async ({
  gisDataFields,
  gisDataValues,
  mapServiceId,
  objectId,
}) => {
  const gisDataValueIds = gisDataValues.map((g) => g.gis_data_field_id);
  const filteredGisDataFields = gisDataFields.filter((g) => {
    return (
      g.map_service_id === mapServiceId && !gisDataValueIds.includes(objectId)
    );
  });
  const gisDataFieldsAndValues = filteredGisDataFields?.map((gdf) => {
    const { name } = gdf;
    const value = gisDataValues?.find((gdv) => {
      return (
        gdv?.gis_data_field_id === gdf?.gis_data_field_id &&
        gdv?.feature_id === objectId
      );
    })?.value;

    return {
      name,
      value,
    };
  });
  const [mapService] = await db.mapServices
    .filter((m) => m.map_service_id === mapServiceId)
    .toArray();
  const { ref_field: refField } = mapService ?? {};
  return gisDataFieldsAndValues?.find((f) => f.name === refField)?.value;
};

export default getUtiliSyncLayerFeatureSiteName;
