<template>
  <div>
    <div class="d-flex justify-space-between px-5 py-2" ref="topToolbar">
      <div class="caption">
        <span v-if="isOnline">
          The Form Submissions Shown Below Have Been Cleared And Are Ready To Be
          Uploaded.
        </span>
        <span v-else class="red--text">
          Device offline. Must be online to upload tickets.
        </span>
      </div>
      <v-btn
        :disabled="
          formSubmissions.length === 0 || selectedFormSubmissions.length === 0
        "
        :dark="
          !(
            formSubmissions.length === 0 || selectedFormSubmissions.length === 0
          )
        "
        @click="submit"
        class="button"
      >
        Upload Form Submissions
      </v-btn>
    </div>

    <v-card class="ma-0 pa-0">
      <v-card-text class="ma-0 pa-0">
        <div class="d-flex align-center px-4">
          <v-checkbox
            v-model="selectAllFormSubmissions"
            :indeterminate="
              selectedFormSubmissions.length > 0 &&
              selectedFormSubmissions.length < formSubmissions.length
            "
          >
          </v-checkbox>
        </div>
      </v-card-text>
    </v-card>

    <section
      v-if="formSubmissions.length > 0"
      :style="{ height: `${tableHeight}px` }"
      class="overflow-y-auto"
    >
      <v-card
        :rounded="false"
        v-for="(item, index) of formSubmissions"
        :key="`${item.formResultId}-${index}`"
        class="mb-3"
      >
        <v-card-title
          class="d-flex justify-space-between"
          @click.stop="$emit('row-clicked', item)"
        >
          <div class="d-flex">
            <v-checkbox
              @click.stop
              :input-value="true"
              :value="selected[item.formResultId]"
              @change="$set(selected, item.formResultId, Boolean($event))"
            ></v-checkbox>
          </div>
        </v-card-title>
        <v-list dense class="mt-n8">
          <v-list-item @click.stop="$emit('row-clicked', item)">
            <v-list-item-content>
              <div class="d-flex">
                <div class="caption field-names mr-2">REF FIELD</div>
                <div class="field-value">{{ getSiteName(item) }}</div>
              </div>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click.stop="$emit('row-clicked', item)">
            <v-list-item-content>
              <div class="d-flex">
                <div class="caption field-names mr-2">FORM</div>
                <div class="field-value">{{ item.formTitle }}</div>
              </div>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click.stop="$emit('row-clicked', item)">
            <v-list-item-content>
              <div class="d-flex">
                <div class="caption field-names mr-2">SUBMITTED BY</div>
                <div class="field-value">{{ item.submittedBy }}</div>
              </div>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click.stop="$emit('row-clicked', item)">
            <v-list-item-content>
              <div class="d-flex">
                <div class="caption field-names mr-2">UPDATED ON</div>
                <div class="field-value">
                  {{ item.updatedOn | formatFullDate }}
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click.stop="$emit('row-clicked', item)">
            <v-list-item-content>
              <div class="d-flex">
                <div class="caption field-names mr-2">LAYER</div>
                <div class="field-value">
                  {{ getMapServiceName(item) }}
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click.stop="$emit('row-clicked', item)">
            <v-list-item-content>
              <div class="d-flex">
                <div class="caption field-names mr-2">OBJECT ID</div>
                <div class="field-value">
                  {{ item.featureId }}
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </section>
    <section v-else>
      <v-card>
        <v-card-text> No data available </v-card-text>
      </v-card>
    </section>

    <v-dialog v-model="showSubmittingDialog" max-width="600px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="text-center">
                Submitting {{ numUnsubmittedTickets }} of
                {{ selected.length }} Forms
              </v-col>
              <v-col cols="12" class="text-center">
                <v-progress-circular
                  indeterminate
                  color="#673AB7"
                ></v-progress-circular>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="showSubmittingDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showErrorDialog" max-width="600px" close>
      <v-card>
        <v-card-title> Error </v-card-title>
        <v-card-text>
          {{ errorMsg }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { axiosWithRegularAuth } from "@/plugins/axios";
import { db } from "@/mixins/utilisync-db";
import { mapGetters, mapMutations } from "vuex";
import networkStatusMixin from "@/mixins/networkStatusMixin";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "MobileUploadTable",
  props: {
    formSubmissions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    siteInfos: {
      type: Array,
      default: () => {
        return [];
      },
    },
    mapServices: {
      type: Array,
      default: () => {
        return [];
      },
    },
    userChoices: Array,
  },
  mixins: [networkStatusMixin],
  data() {
    return {
      selectAllFormSubmissions: false,
      selected: {},
      showSubmittingDialog: false,
      showErrorDialog: false,
      errorMsg: "",
      isOnline: true,
      tableHeight: 0,
    };
  },
  computed: {
    selectedFormSubmissions() {
      return this.formSubmissions.filter((t) => {
        return Object.keys(this.selected)
          .filter((key) => this.selected[key])
          .includes(t.formResultId);
      });
    },
    ...mapGetters(["numUnsubmittedTickets"]),
  },
  methods: {
    onResize() {
      const height =
        window.innerHeight - (this.$refs.topToolbar?.clientHeight ?? 40) - 140;
      this.tableHeight = Math.max(height, 200);
    },
    getMapServiceName(formResult) {
      return this.mapServices.find(
        (m) => m.map_service_id === formResult?.mapServiceId
      )?.service_name;
    },
    getSiteName(formResult) {
      const site = this.siteInfos.find((s) => {
        return (
          s?.feature_id === formResult?.featureId &&
          s?.map_service_id === formResult?.mapServiceId
        );
      });
      return site?.siteName;
    },
    async submit() {
      this.showSubmittingDialog = true;
      const formResults = await db.formResults.toCollection().toArray();
      const selected = formResults.filter((fr) => {
        return Object.keys(this.selected).includes(fr.form_result_id);
      });
      try {
        for (const s of selected) {
          if (navigator.onLine) {
            const formResult = formResults.find(
              (u) => u.form_result_id === s.form_result_id
            );
            await axiosWithRegularAuth.put(
              `${APIURL}/form_result_values/${s.form_result_id}`,
              formResult.payload
            );
            await db.formResults
              .where("form_result_id")
              .equals(s.form_result_id)
              .delete();
          }
        }
        this.showSubmittingDialog = false;
        this.setNumUnsubmittedTickets(0);
        this.$emit("tickets-submitted");
        this.selected = {};
        this.selectAllFormSubmissions = false;
      } catch (error) {
        this.showSubmittingDialog = false;
        if (Array.isArray(error?.response?.data?.error)) {
          const [errorMsg] = error?.response?.data?.error;
          this.errorMsg = errorMsg;
        } else {
          this.errorMsg = error?.response?.data?.error;
        }
        this.showErrorDialog = true;
      }
    },
    selectAll() {
      for (const { formResultId } of this.formSubmissions) {
        this.$set(this.selected, formResultId, true);
      }
    },
    ...mapMutations(["setNumUnsubmittedTickets"]),
  },
  watch: {
    selectAllFormSubmissions: {
      immediate: true,
      handler(val) {
        if (val) {
          this.selectAll();
        } else {
          this.selected = {};
        }
      },
    },
    formSubmissions: {
      deep: true,
      handler(val) {
        this.selectAllFormSubmissions = val.length > 0;
        this.selectAll();
      },
    },
  },
  async mounted() {
    this.selectAllFormSubmissions = true;
    this.selectAll();
    window.addEventListener("resize", this.onResize);
    await this.$nextTick();
    this.onResize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
