<template>
  <v-app>
    <v-main fluid tag="section" class="pt-0">
      <TopBar title="Upload" @show-notifications="onShowNotifications" />
      <v-navigation-drawer
        :app="!$vuetify.breakpoint.xsOnly"
        v-model="showNotificationPane"
        right
        :permanent="showNotificationPane"
        hide-overlay
        width="375px"
        stateless
        :absolute="$vuetify.breakpoint.xsOnly"
        :style="{
          'z-index': $vuetify.breakpoint.xsOnly ? 10 : 1,
          'background-color': '#fafafa',
        }"
      >
        <NotificationsPane
          v-if="showNotificationPane"
          @close-notification-pane="showNotificationPane = false"
        />
      </v-navigation-drawer>

      <v-navigation-drawer
        app
        v-model="showGisInfoPanel"
        style="z-index: 100"
        hide-overlay
        width="375px"
        :permanent="showGisInfoPanel && $vuetify.breakpoint.smAndUp"
        right
        :stateless="$vuetify.breakpoint.smAndUp"
        class="elevation-3"
      >
        <div
          class="d-flex flex-column"
          style="height: 100%"
          v-if="showGisInfoPanel"
          id="infoPanel"
        >
          <GisInfoTopCard
            :selectedMapServiceId="selectedMapServiceId"
            :globalId="gisInfoId"
            :objectId="objectId"
            :sharedSite="sharedSite"
            @gis-info-panel-close="onGisInfoPanelClose"
            @open-expand-panel-dialog="showExpansionPanelDialog = true"
            @edited-site="createSharedSiteIfNotExist"
          />

          <GisInfoButtonBar
            @gis-info-button-clicked="gisInfoTab = $event"
            v-if="!['S'].includes(selectedLayerType)"
            :globalId="gisInfoId"
            :selectedMapServiceId="selectedMapServiceId"
            :taskCount="taskCount"
          />

          <div class="background">
            <TicketFormDefDropdown
              v-if="showGisInfoPanel"
              inRightPane
              :globalId="gisInfoId"
              :selectedMapServiceId="selectedMapServiceId"
              :objectId="objectId"
              :selectedGisInfoObj="selectedGisInfoObj"
              @ticket-edit-form-submitted="onTicketEditFormSubmitted()"
              @show-edit-dialog="openGisInfoPanels = [1]"
            />
          </div>

          <v-divider></v-divider>

          <SharePublicSite
            v-if="showGisInfoPanel"
            v-show="selectedLayer.site_enabled"
            :selectedMapServiceId="selectedMapServiceId"
            :objectId="objectId"
            :attributes="{}"
            :sharedSite="sharedSite"
            :globalId="gisInfoId"
            @public-link-created="createSharedSiteIfNotExist"
            @removed-sharing="createSharedSiteIfNotExist"
            @site-name-saved="createSharedSiteIfNotExist"
          />

          <v-divider v-if="selectedLayer.site_enabled"></v-divider>

          <div
            v-if="['U'].includes(selectedLayerType)"
            class="flex-grow-1 overflow-y-auto background"
          >
            <SortedGisInfo
              v-if="showGisInfoPanel"
              inRightPane
              :globalId="gisInfoId"
              :selectedMapServiceId="selectedMapServiceId"
              :objectId="objectId"
            />
          </div>
        </div>
      </v-navigation-drawer>

      <v-navigation-drawer
        :app="!$vuetify.breakpoint.xsOnly"
        v-model="showTasksTab"
        right
        :permanent="showTasksTab"
        style="z-index: 101"
        hide-overlay
        width="375px"
        stateless
        :absolute="$vuetify.breakpoint.xsOnly"
      >
        <div class="rounded-0 d-flex flex-column" style="height: 100vh">
          <v-list color="#3F51B5" class="my-0 py-1" width="100%">
            <v-list-item>
              <v-list-item-content class="py-0 my-0" two-line>
                <div class="d-flex justify-space-between align-center">
                  <div class="white--text">Tasks</div>
                  <v-btn
                    icon
                    text
                    @click="
                      showTasksTab = false;
                      gisInfoTab = undefined;
                    "
                    style="font-size: 12px"
                  >
                    <v-icon color="white">
                      {{ mdiClose }}
                    </v-icon>
                  </v-btn>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <template v-if="showTasksTab">
            <TasksTab
              class="overflow-y-auto"
              v-if="['U'].includes(selectedLayerType)"
              :globalId="gisInfoId"
              :selectedMapServiceId="selectedMapServiceId"
              :objectId="objectId"
              @update-task-count="taskCount = $event"
            />
          </template>
        </div>
      </v-navigation-drawer>

      <v-navigation-drawer
        :app="!$vuetify.breakpoint.xsOnly"
        v-model="showTicketLogTab"
        right
        :permanent="showTicketLogTab"
        style="z-index: 101"
        hide-overlay
        width="375px"
        stateless
        :absolute="$vuetify.breakpoint.xsOnly"
      >
        <div class="rounded-0 d-flex flex-column" style="height: 100vh">
          <v-list color="#3F51B5" class="my-0 py-1" width="100%">
            <v-list-item>
              <v-list-item-content class="py-0 my-0" two-line>
                <div class="d-flex justify-space-between align-center">
                  <div class="white--text">Log</div>
                  <v-btn
                    icon
                    text
                    @click="
                      showTicketLogTab = false;
                      gisInfoTab = undefined;
                    "
                    style="font-size: 12px"
                  >
                    <v-icon color="white">
                      {{ mdiClose }}
                    </v-icon>
                  </v-btn>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <template v-if="showTicketLogTab">
            <Log
              class="overflow-y-auto"
              v-if="['U'].includes(selectedLayerType)"
              :globalId="gisInfoId"
              :selectedMapServiceId="selectedMapServiceId"
              :objectId="objectId"
            />
          </template>
        </div>
      </v-navigation-drawer>

      <v-navigation-drawer
        :app="!$vuetify.breakpoint.xsOnly"
        v-model="showDocsTab"
        right
        :permanent="showDocsTab"
        style="z-index: 101"
        hide-overlay
        width="375px"
        stateless
        :absolute="$vuetify.breakpoint.xsOnly"
      >
        <div class="rounded-0 d-flex flex-column" style="height: 100vh">
          <v-list color="#3F51B5" class="my-0 py-1" width="100%">
            <v-list-item>
              <v-list-item-content class="py-0 my-0" two-line>
                <div class="d-flex justify-space-between align-center">
                  <div class="white--text">Documents</div>
                  <v-btn
                    icon
                    text
                    @click="
                      showDocsTab = false;
                      gisInfoTab = undefined;
                    "
                    style="font-size: 12px"
                  >
                    <v-icon color="white">
                      {{ mdiClose }}
                    </v-icon>
                  </v-btn>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <template v-if="showDocsTab">
            <DocsTab
              class="overflow-y-auto"
              v-if="['U'].includes(selectedLayerType)"
              :globalId="gisInfoId"
              :selectedMapServiceId="selectedMapServiceId"
              :objectId="objectId"
            />
          </template>
        </div>
      </v-navigation-drawer>

      <ExpandInfoPanelDialog
        v-if="['U'].includes(selectedLayerType)"
        :showExpansionPanelDialog="showExpansionPanelDialog"
        :globalId="gisInfoId"
        :selectedMapServiceId="selectedMapServiceId"
        :objectId="objectId"
        :selectedGisInfoObj="{}"
        @expand-info-panel-close="showExpansionPanelDialog = false"
      />

      <section v-if="$vuetify.breakpoint.xsOnly">
        <MobileUploadTable
          :formSubmissions="mappedFormSubmissions"
          :userChoices="userChoices"
          :siteInfos="siteInfos"
          :mapServices="mapServices"
          @tickets-submitted="getSubmittedFormSubmissions"
          @row-clicked="handleRowClick"
        />
      </section>
      <section v-else class="pt-3">
        <div
          class="d-flex justify-space-between align-center px-5"
          ref="topToolbar"
        >
          <div class="caption">
            <span v-if="isOnline">
              The Form Submissions Shown Below Have Been Cleared And Are Ready
              To Be Uploaded.
            </span>
            <span v-else class="red--text">
              Device offline. Must be online to upload form submission.
            </span>
          </div>

          <section class="d-flex">
            <v-btn
              @click="submit"
              class="button"
              :dark="
                isOnline &&
                unsubmittedFormSubmissions.length > 0 &&
                selected.length > 0
              "
              color="#3F51B5"
              :disabled="
                !isOnline ||
                unsubmittedFormSubmissions.length === 0 ||
                selected.length === 0
              "
            >
              Upload Form Submission
            </v-btn>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  text
                  color="#3F51B5"
                  class="px-1 ml-3"
                >
                  <v-icon dark class="mr-1">
                    {{ mdiPlusCircle }}
                  </v-icon>
                  Field
                </v-btn>
              </template>

              <v-card>
                <v-card-text>
                  <v-checkbox
                    v-for="h of nonEmptyHeaders"
                    :key="h.value"
                    @click.stop
                    v-model="headersEnabled[h.value]"
                    :label="h.text"
                    class="py-0 my-0"
                  >
                  </v-checkbox>
                </v-card-text>
              </v-card>
            </v-menu>
          </section>
        </div>

        <v-data-table
          :headers="filteredHeaders"
          :items="mappedFormSubmissions"
          item-key="formResultId"
          class="pa-0 ma-0 cursor-pointer"
          @click:row="handleRowClick"
          hide-default-footer
          disable-pagination
          :height="tableHeight"
          fixed-header
          :loading="loading"
          show-select
          v-model="selected"
          v-if="!loadingFormResults || !isOnline"
        >
          <template v-slot:no-data>
            <div
              v-html="
                isOnline
                  ? 'No data available'
                  : 'This device is offline. <u>Reload</u> the page when you are back online to load the Form Submissions'
              "
            ></div>
          </template>

          <template v-slot:[`item.updatedOn`]="{ item }">
            {{ item.updatedOn | formatFullDate }}
          </template>

          <template v-slot:[`item.formTitle`]="{ item }">
            {{ item.formTitle }}
          </template>

          <template v-slot:[`item.refField`]="{ item }">
            {{ getSiteName(item) }}
          </template>

          <template v-slot:[`item.overallTasksStatus`]="{ item }">
            <v-icon
              v-if="
                item.overallTasksStatus === FORM_RESULT_STATUS.COMPLETED_ERROR
              "
              color="#FFAE42"
            >
              {{ mdiAlert }}
            </v-icon>
            <v-icon
              v-else-if="
                item.overallTasksStatus === FORM_RESULT_STATUS.PROCESSING
              "
              color="#00A3FF"
            >
              {{ mdiAutorenew }}
            </v-icon>
            <v-icon
              v-else-if="
                item.overallTasksStatus === FORM_RESULT_STATUS.COMPLETED_SUCCESS
              "
              color="green"
            >
              {{ mdiCheck }}
            </v-icon>
            <v-icon
              v-else-if="
                item.overallTasksStatus ===
                  FORM_RESULT_STATUS.DEAD_LETTER_QUEUE ||
                item.overallTasksStatus === FORM_RESULT_STATUS.ERROR
              "
              color="red"
            >
              {{ mdiCloseCircleOutline }}
            </v-icon>
          </template>

          <template v-slot:[`item.mapServiceId`]="{ item }">
            {{ getMapServiceName(item) }}
          </template>

          <template v-slot:[`item.featureId`]="{ item }">
            {{ item.featureId }}
          </template>
        </v-data-table>
        <div v-else class="d-flex justify-center align-center">
          <v-progress-circular
            indeterminate
            color="#3F51B5"
          ></v-progress-circular>
        </div>
      </section>

      <v-dialog v-model="showSubmittingDialog" max-width="600px">
        <v-card>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="text-center">
                  Submitting {{ numUnsubmittedTickets }} of
                  {{ selected.length }} Forms
                </v-col>
                <v-col cols="12" class="text-center">
                  <v-progress-circular
                    indeterminate
                    color="#3F51B5"
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#673AB7" text @click="showSubmittingDialog = false">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <AlreadyClearedDialog
        :showAlreadyClearedDialog="showAlreadyClearedDialog"
        :ticketNumbersAlreadySubmitted="ticketNumbersAlreadySubmitted"
        :formResultIdsAlreadySubmitted="formResultIdsAlreadySubmitted"
        @delete-forms="showAlreadyClearedDialog = false"
        @keep-forms="showAlreadyClearedDialog = false"
      />

      <v-dialog v-model="showErrorDialog" max-width="600px" close>
        <v-card>
          <v-card-title> Error </v-card-title>
          <v-card-text>
            {{ errorMsg }}
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-navigation-drawer
        :app="!$vuetify.breakpoint.xsOnly"
        v-model="showNotificationPane"
        right
        :permanent="showNotificationPane"
        hide-overlay
        width="375px"
        stateless
        :absolute="$vuetify.breakpoint.xsOnly"
        :style="{ 'z-index': $vuetify.breakpoint.xsOnly ? 10 : 1 }"
      >
        <NotificationsPane
          v-if="showNotificationPane"
          @close-notification-pane="showNotificationPane = false"
        />
      </v-navigation-drawer>

      <v-dialog
        v-model="showEditFormDialog"
        max-width="600px"
        persistent
        :fullscreen="isFullScreen"
      >
        <v-card>
          <DynamicForm
            :formDefinition="dynamicFormProps.formDefinition"
            :existingFormResultIdMap="dynamicFormProps.existingFormResultIdMap"
            :selectedPdfFileUrl="dynamicFormProps.selectedPdfFileUrl"
            :canEdit="dynamicFormProps.canEdit"
            :alreadySubmittedFinalOnline="
              dynamicFormProps.alreadySubmittedFinalOnline
            "
            :globalId="dynamicFormProps.globalId"
            :objectId="dynamicFormProps.objectId"
            :selectedMapServiceId="dynamicFormProps.selectedMapServiceId"
            @ticket-edit-form-close-button-click="showEditFormDialog = false"
            @ticket-edit-form-close="showEditFormDialog = false"
            @ticket-edit-form-submitted="showEditFormDialog = false"
            v-if="showEditFormDialog"
          />
        </v-card>
      </v-dialog>

      <AuthExpiredDialog
        v-if="showAuthExpiredDialog"
        :showAuthExpiredDialog="showAuthExpiredDialog"
        @portal-login-success="showAuthExpiredDialog = false"
        @auth-expired-dialog-close="showAuthExpiredDialog = false"
      />
    </v-main>
  </v-app>
</template>

<script>
import TopBar from "@/components/app/TopBar.vue";
import { db } from "@/mixins/utilisync-db";
import { axiosWithNoAuth, axiosWithRegularAuth } from "@/plugins/axios";
import { mapGetters, mapMutations } from "vuex";
import AlreadyClearedDialog from "@/components/shared/AlreadyClearedDialog";
import {
  mdiClose,
  mdiMinus,
  mdiDotsVertical,
  mdiDelete,
  mdiInformation,
  mdiBookOpenOutline,
  mdiNotePlusOutline,
  mdiPlusCircle,
} from "@mdi/js";
import MobileUploadTable from "@/components/upload/MobileUploadTable.vue";
import NotificationsPane from "@/components/shared/NotificationsPane.vue";
import networkStatusMixin from "@/mixins/networkStatusMixin";
import usetifulMixin from "@/mixins/usetifulMixin";
import getUtiliSyncLayerFeatureSiteName from "@/mixins/getUtiliSyncLayerFeatureSiteName";
import FORM_RESULT_STATUS from "@/constants/formResultStatus";
import GisInfoTopCard from "@/components/mapView/GisInfoTopCard";
import GisInfoButtonBar from "@/components/mapView/GisInfoButtonBar.vue";
import TicketFormDefDropdown from "@/components/tickets/TicketFormDefDropdown.vue";
import DocsTab from "@/components/mapView/DocsTab.vue";
import TasksTab from "@/components/mapView/TasksTab.vue";
import SharePublicSite from "@/components/app/SharePublicSite";
import sharedSiteMixin from "@/mixins/sharedSiteMixin";
import TABS from "@/constants/tabs";
import Log from "@/components/tickets/Log.vue";
import ExpandInfoPanelDialog from "@/components/mapView/ExpandInfoPanelDialog.vue";
import DynamicForm from "@/components/tickets/ticket-edit-form/DynamicForm";
import fullScreenCheckMixin from "@/mixins/fullScreenCheckMixin";
import getActiveTaskCountMixin from "@/mixins/getActiveTaskCountMixin";
import AuthExpiredDialog from "@/components/app/AuthExpiredDialog";
import validateEsriTokenMixin from "@/mixins/validateEsriTokenMixin";
import SortedGisInfo from "@/components/mapView/SortedGisInfo";
import notificationPaneMixin from "@/mixins/notificationPaneMixin";
import axios from "axios";

const APIURL = process.env.VUE_APP_API_URL;
const headers = [
  {
    text: "Reference Field",
    align: "start",
    value: "refField",
    sortable: true,
    minWidth: "150px",
    width: "150px",
  },
  {
    text: "Form",
    align: "start",
    value: "formTitle",
    sortable: true,
    minWidth: "150px",
    width: "150px",
  },
  {
    text: "Submitted By",
    align: "start",
    value: "submittedBy",
    sortable: true,
    minWidth: "150px",
    width: "150px",
  },
  {
    text: "Last Updated",
    align: "start",
    value: "updatedOn",
    sortable: true,
    minWidth: "150px",
    width: "150px",
  },
  {
    text: "Layer",
    align: "start",
    value: "mapServiceId",
    sortable: true,
    minWidth: "150px",
    width: "150px",
  },
  {
    text: "Object ID",
    align: "start",
    value: "featureId",
    sortable: true,
    minWidth: "120px",
    width: "120px",
  },
];

export default {
  name: "Upload",
  components: {
    TopBar,
    AlreadyClearedDialog,
    MobileUploadTable,
    NotificationsPane,
    GisInfoTopCard,
    GisInfoButtonBar,
    TicketFormDefDropdown,
    DocsTab,
    TasksTab,
    SharePublicSite,
    Log,
    ExpandInfoPanelDialog,
    DynamicForm,
    AuthExpiredDialog,
    SortedGisInfo,
  },
  mixins: [
    networkStatusMixin,
    usetifulMixin,
    sharedSiteMixin,
    fullScreenCheckMixin,
    getActiveTaskCountMixin,
    validateEsriTokenMixin,
    notificationPaneMixin,
  ],
  data() {
    return {
      showNotificationPane: false,
      unsubmittedTickets: [],
      selected: [],
      showSubmittingDialog: false,
      selectedFormResult: {},
      selectedLocateRequestId: undefined,
      existingFormResultIdMap: undefined,
      showAlreadyClearedDialog: false,
      ticketNumbersAlreadySubmitted: [],
      formResultIdsAlreadySubmitted: [],
      showErrorDialog: false,
      errorMsg: "",
      mdiClose,
      openPanels: [1],
      allClosedTickets: [],
      tickets: [],
      showGisInfoPanel: false,
      selectedLocateRequestNumber: "",
      selectedMapServiceId: undefined,
      users: [],
      isOnline: navigator.onLine,
      showBottomSheet: false,
      mdiMinus,
      mdiDotsVertical,
      mdiDelete,
      mdiInformation,
      mdiBookOpenOutline,
      mdiNotePlusOutline,
      mdiPlusCircle,
      unsubmittedFormSubmissions: [],
      headers,
      headersEnabled: {
        refField: true,
        formTitle: true,
        submittedBy: true,
        updatedOn: true,
        overallTasksStatus: true,
        menu: true,
        mapServiceId: false,
        featureId: false,
      },
      loading: false,
      tableHeight: 0,
      siteInfos: [],
      FORM_RESULT_STATUS,
      attributes: {},
      objectId: undefined,
      gisInfoId: "",
      featureItemFields: [],
      mapServices: [],
      selectedGisInfoIndex: 0,
      showTicketLogTab: false,
      showTasksTab: false,
      showDocsTab: false,
      taskCount: undefined,
      gisInfoTab: undefined,
      loadingFormResults: false,
      showExpansionPanelDialog: false,
      showEditFormDialog: false,
    };
  },
  computed: {
    ...mapGetters(["numUnsubmittedTickets", "dynamicFormProps"]),
    selectedLayerFields() {
      return this.selectedLayer?.fields;
    },
    selectedGisInfoObj() {
      return this.gisInfos?.[this.selectedGisInfoIndex];
    },
    notUtiliSyncLayer() {
      const mapService = this.mapServices.find(
        (m) => m?.map_service_id === this.selectedTask?.mapServiceId
      );
      return mapService?.service_type !== "U";
    },
    selectedLayer() {
      const selectedLayer = this.mapServices.find(
        (m) => m.map_service_id === this.selectedMapServiceId
      );
      return { ...selectedLayer };
    },
    selectedLayerType() {
      return this.mapServices.find(
        (m) => m.map_service_id === this.selectedMapServiceId
      )?.service_type;
    },
    userChoices() {
      const allUsers = { value: "all-users", name: "All Users" };
      const unAssigned = { value: null, name: "Unassigned" };
      if (!Array.isArray(this.users)) {
        return [allUsers, unAssigned];
      }
      const users = this.users.map(
        ({ user_id: userId, f_name: fName, l_name: lName }) => ({
          value: userId,
          name: `${fName} ${lName}`,
        })
      );
      return [allUsers, unAssigned, ...users];
    },
    mappedFormSubmissions() {
      const { f_name: fName, l_name: lName } = JSON.parse(
        localStorage.getItem("auth")
      );
      return this.unsubmittedFormSubmissions.map((f) => {
        const { updatedOn } = f;
        return {
          submittedBy: `${fName} ${lName}`,
          featureId: f?.payload?.feature_id,
          formResultId: f?.form_result_id,
          formTitle: f?.computed_form_definition?.form?.formDescription?.title,
          mapServiceId: f?.computed_form_definition?.map_service_id,
          updatedOn,
        };
      });
    },
    filteredHeaders() {
      return this.headers.filter((h) => {
        return this.headersEnabled[h.value] || h.value === "menu";
      });
    },
    nonEmptyHeaders() {
      return this.headers.filter((h) => {
        return Boolean(h.text);
      });
    },
  },
  methods: {
    onGisInfoPanelClose() {
      this.showGisInfoPanel = false;
      this.gisInfos = [];
    },
    async getMapServices() {
      this.mapServices = await db.mapServices.toCollection().toArray();
    },
    getMapServiceName(formResult) {
      return this.mapServices.find(
        (m) => m.map_service_id === formResult?.mapServiceId
      )?.service_name;
    },
    onResize() {
      const height =
        window.innerHeight - (this.$refs.topToolbar?.clientHeight ?? 40) - 76;
      this.tableHeight = Math.max(height, 200);

      if (this.$vuetify.breakpoint.xsOnly) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "auto";
      }
    },
    async onOnlineStatusChange() {
      this.isOnline = navigator.onLine;
    },
    onShowNotifications() {
      this.showNotificationPane = true;
    },
    getCachedFormSubmissions() {
      return db.formResults
        .filter((fr) => fr.payload.status === "SUBMITTED_FINAL")
        .toArray();
    },
    async getSubmittedFormSubmissions() {
      this.loadingFormResults = true;
      let lastSavedTimes = {};
      try {
        lastSavedTimes =
          JSON.parse(localStorage.getItem("last-saved-times")) || {};
      } catch (error) {
        lastSavedTimes = {};
      }
      const unsubmittedFormSubmissions = await this.getCachedFormSubmissions();
      this.unsubmittedFormSubmissions = unsubmittedFormSubmissions.map((u) => {
        return { ...u, savedToDevice: lastSavedTimes[u.form_result_id] };
      });
      this.loadingFormResults = false;
    },
    getSiteName(formResult) {
      const site = this.siteInfos.find((s) => {
        return (
          s?.featureId === formResult?.featureId &&
          s?.mapServiceId === formResult?.mapServiceId
        );
      });
      return site?.siteName;
    },
    async submit() {
      this.showSubmittingDialog = true;
      try {
        for (const s of this.selected) {
          if (!this.showSubmittingDialog) {
            break;
          }

          if (navigator.onLine) {
            const formResult = this.unsubmittedFormSubmissions.find(
              (u) => u.form_result_id === s.formResultId
            );
            await axiosWithRegularAuth.put(
              `${APIURL}/form_result_values/${s.formResultId}`,
              formResult.payload
            );
            await db.formResults
              .where("form_result_id")
              .equals(s.formResultId)
              .delete();
          }
        }
        this.showSubmittingDialog = false;
        this.setNumUnsubmittedTickets(0);
        await this.getSubmittedFormSubmissions();
      } catch (error) {
        console.log(error);
        this.showSubmittingDialog = false;
        if (Array.isArray(error?.response?.data?.error)) {
          const [errorMsg] = error?.response?.data?.error;
          this.errorMsg = errorMsg;
        } else {
          this.errorMsg = error?.response?.data?.error;
        }
        this.showErrorDialog = true;
      }
    },
    async getNumUnsubmittedTickets() {
      const formResults = await db.formResults
        .filter((f) => f.payload?.status === "SUBMITTED_FINAL")
        .toArray();
      this.setNumUnsubmittedTickets(formResults.length);
    },
    async getFormDefinition(formDefinitionId) {
      const [formDefinition] = await db.formDefinitions
        .filter(
          (formDefinition) =>
            formDefinition.form_definition_id === formDefinitionId
        )
        .toArray();
      return formDefinition;
    },
    findFormValue(formValues, itemId) {
      for (const s of formValues.sections) {
        for (const item of s.items) {
          if (item.id === itemId) {
            return item.value;
          }
        }
      }
    },
    async loadData(value) {
      this.selectedRowValue = value;
      const { featureId, mapServiceId: selectedMapServiceId } = value;
      this.selectedMapServiceId = selectedMapServiceId;
      const [selectedLayer] = await db.mapServices
        .filter((m) => m.map_service_id === selectedMapServiceId)
        .toArray();
      if (selectedLayer?.service_type === "U") {
        this.selectedGisInfo = value;
        const [point] = await db.gisDataPoints
          .filter((p) => {
            return (
              p.map_service_id === selectedMapServiceId &&
              p.object_id === featureId
            );
          })
          .toArray();
        this.gisInfoId = point?.gis_data_point_id;
        this.objectId = featureId;
        this.showGisInfoPanel = true;
      }
    },
    async handleRowClick(value) {
      this.showGisInfoPanel = false;
      this.showFeatureLayerGisInfoPanel = false;
      await this.loadData(value);
    },
    async getUsers() {
      const collection = await db.users.orderBy("f_name");
      this.users = await collection.toArray();
    },
    async getGisInfo() {
      const mapServiceIds = [
        ...new Set(this.mappedFormSubmissions.map((r) => r.mapServiceId)),
      ];
      const queryPromises = mapServiceIds.map(async (mapServiceId) => {
        const resultsWithMapServiceId = this.mappedFormSubmissions.filter(
          (r) => r.map_service_id === mapServiceId
        );
        const objectIds = resultsWithMapServiceId.map((r) => r.feature_id);
        const [mapService] = await db.mapServices
          .filter((m) => m.map_service_id === mapServiceId)
          .toArray();
        const mapServiceUrl = mapService?.service_url;
        if (!mapServiceUrl) {
          return;
        }
        try {
          const source = axios.CancelToken.source();
          const timeout = setTimeout(() => {
            source.cancel();
          }, 2000);
          const { data: queryResult } = await axiosWithNoAuth.get(
            `${mapServiceUrl}/query`,
            {
              cancelToken: source.token,
              params: {
                objectids: [...new Set(objectIds)].join(","),
                outFields: "*",
                f: "json",
                token: localStorage.getItem("esri_token"),
              },
            }
          );
          clearTimeout(timeout);
          const { objectIdFieldName, features, error } = queryResult;
          if (error) {
            return [];
          }
          return resultsWithMapServiceId.map((r) => {
            const feature = features?.find(
              (f) => f?.attributes?.[objectIdFieldName] === r.feature_id
            );
            return {
              ...r,
              siteName: feature?.attributes?.[mapService?.ref_field],
            };
          });
        } catch (error) {
          return [];
        }
      });
      const siteInfos = await Promise.all(queryPromises);
      const featureLayerSiteNames = siteInfos.flat();

      const gisDataFields = await db.gisDataFields.toCollection().toArray();
      const gisDataValues = await db.gisDataValues.toCollection().toArray();
      const mapServices = await db.mapServices
        .filter((m) => m.service_type === "U")
        .toArray();
      const utiliSyncLayerSiteNamePromises = mapServices.map(async (m) => {
        const { map_service_id: mapServiceId } = m;
        const gisDataPoints = await db.gisDataPoints
          .filter((p) => p.map_service_id === mapServiceId)
          .toArray();
        const promises = gisDataPoints.map(async (p) => {
          const siteName = await getUtiliSyncLayerFeatureSiteName({
            gisDataFields,
            gisDataValues,
            mapServiceId,
            objectId: p.object_id,
          });
          return {
            featureId: p.object_id,
            mapServiceId: mapServiceId,
            siteName,
          };
        });
        return Promise.all(promises);
      });
      const utiliSyncLayerSiteNames = (
        await Promise.all(utiliSyncLayerSiteNamePromises)
      ).flat();
      this.siteInfos = [...featureLayerSiteNames, ...utiliSyncLayerSiteNames];
    },
    async getFormDataByFormResultIds() {
      const {
        data: { results },
      } = await axiosWithRegularAuth.post(
        `${APIURL}/form_results/report/utilibot_summary/all/form_data`,
        {
          formResultIds: this.formSubmissions.map((f) => f.form_result_id),
        }
      );
      this.formSubmissionData = results;
    },
    async reloadData() {
      await this.getSubmittedFormSubmissions();
      this.loadingFormResults = true;
      await this.getUsers();
      await this.getGisInfo();
      await this.getMapServices();
      this.loadingFormResults = false;
      await this.getNumUnsubmittedTickets();
    },
    ...mapMutations(["setNumUnsubmittedTickets"]),
  },
  beforeMount() {
    this.checkEsriTokenExpirationPeriodically();
  },
  async mounted() {
    document.body.style.overflowY = "hidden";
    this.loadingFormResults = true;
    const [elHtml] = document.getElementsByTagName("html");
    elHtml.style.overflowY = "hidden";
    await this.getSubmittedFormSubmissions();
    this.loadingFormResults = true;
    await this.getUsers();
    await this.getGisInfo();
    await this.getMapServices();
    window.addEventListener("online", this.onOnlineStatusChange);
    window.addEventListener("online", this.reloadData);
    window.addEventListener("offline", this.onOnlineStatusChange);
    window.addEventListener("resize", this.onResize);
    await this.$nextTick();
    this.onResize();
    this.loadingFormResults = false;
    await this.getNumUnsubmittedTickets();
  },
  beforeDestroy() {
    document.body.style.overflowY = "auto";
    const [elHtml] = document.getElementsByTagName("html");
    elHtml.style.overflowY = "auto";
    window.removeEventListener("online", this.onOnlineStatusChange);
    window.removeEventListener("online", this.reloadData);
    window.removeEventListener("offline", this.onOnlineStatusChange);
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    dynamicFormProps: {
      deep: true,
      async handler(val) {
        this.showEditFormDialog = false;
        await this.$nextTick();
        if (
          val?.formDefinition?.form &&
          val?.objectId &&
          val?.selectedMapServiceId
        ) {
          this.showEditFormDialog = true;
        }
      },
    },
    numUnsubmittedTickets() {
      this.getSubmittedFormSubmissions();
    },
    gisInfoTab(val) {
      this.showTicketLogTab = false;
      this.showTasksTab = false;
      this.showDocsTab = false;
      if (val === TABS.TASKS_TAB) {
        this.showTasksTab = true;
      } else if (val === TABS.TICKET_LOG_TAB) {
        this.showTicketLogTab = true;
      } else if (val === TABS.DOCS_TAB) {
        this.showDocsTab = true;
      }
    },
    "$vuetify.breakpoint.xsOnly"(currVal, oldVal) {
      if (currVal !== oldVal) {
        this.gisInfoTab = undefined;
        this.showGisInfoPanel = false;
        this.showTicketLogTab = false;
        this.showTasksTab = false;
        this.showDocsTab = false;
      }
    },
  },
};
</script>
