var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-main",
        { staticClass: "pt-0", attrs: { fluid: "", tag: "section" } },
        [
          _c("TopBar", {
            attrs: { title: "Upload" },
            on: { "show-notifications": _vm.onShowNotifications }
          }),
          _c(
            "v-navigation-drawer",
            {
              style: {
                "z-index": _vm.$vuetify.breakpoint.xsOnly ? 10 : 1,
                "background-color": "#fafafa"
              },
              attrs: {
                app: !_vm.$vuetify.breakpoint.xsOnly,
                right: "",
                permanent: _vm.showNotificationPane,
                "hide-overlay": "",
                width: "375px",
                stateless: "",
                absolute: _vm.$vuetify.breakpoint.xsOnly
              },
              model: {
                value: _vm.showNotificationPane,
                callback: function($$v) {
                  _vm.showNotificationPane = $$v
                },
                expression: "showNotificationPane"
              }
            },
            [
              _vm.showNotificationPane
                ? _c("NotificationsPane", {
                    on: {
                      "close-notification-pane": function($event) {
                        _vm.showNotificationPane = false
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-navigation-drawer",
            {
              staticClass: "elevation-3",
              staticStyle: { "z-index": "100" },
              attrs: {
                app: "",
                "hide-overlay": "",
                width: "375px",
                permanent:
                  _vm.showGisInfoPanel && _vm.$vuetify.breakpoint.smAndUp,
                right: "",
                stateless: _vm.$vuetify.breakpoint.smAndUp
              },
              model: {
                value: _vm.showGisInfoPanel,
                callback: function($$v) {
                  _vm.showGisInfoPanel = $$v
                },
                expression: "showGisInfoPanel"
              }
            },
            [
              _vm.showGisInfoPanel
                ? _c(
                    "div",
                    {
                      staticClass: "d-flex flex-column",
                      staticStyle: { height: "100%" },
                      attrs: { id: "infoPanel" }
                    },
                    [
                      _c("GisInfoTopCard", {
                        attrs: {
                          selectedMapServiceId: _vm.selectedMapServiceId,
                          globalId: _vm.gisInfoId,
                          objectId: _vm.objectId,
                          sharedSite: _vm.sharedSite
                        },
                        on: {
                          "gis-info-panel-close": _vm.onGisInfoPanelClose,
                          "open-expand-panel-dialog": function($event) {
                            _vm.showExpansionPanelDialog = true
                          },
                          "edited-site": _vm.createSharedSiteIfNotExist
                        }
                      }),
                      !["S"].includes(_vm.selectedLayerType)
                        ? _c("GisInfoButtonBar", {
                            attrs: {
                              globalId: _vm.gisInfoId,
                              selectedMapServiceId: _vm.selectedMapServiceId,
                              taskCount: _vm.taskCount
                            },
                            on: {
                              "gis-info-button-clicked": function($event) {
                                _vm.gisInfoTab = $event
                              }
                            }
                          })
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "background" },
                        [
                          _vm.showGisInfoPanel
                            ? _c("TicketFormDefDropdown", {
                                attrs: {
                                  inRightPane: "",
                                  globalId: _vm.gisInfoId,
                                  selectedMapServiceId:
                                    _vm.selectedMapServiceId,
                                  objectId: _vm.objectId,
                                  selectedGisInfoObj: _vm.selectedGisInfoObj
                                },
                                on: {
                                  "ticket-edit-form-submitted": function(
                                    $event
                                  ) {
                                    return _vm.onTicketEditFormSubmitted()
                                  },
                                  "show-edit-dialog": function($event) {
                                    _vm.openGisInfoPanels = [1]
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _vm.showGisInfoPanel
                        ? _c("SharePublicSite", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.selectedLayer.site_enabled,
                                expression: "selectedLayer.site_enabled"
                              }
                            ],
                            attrs: {
                              selectedMapServiceId: _vm.selectedMapServiceId,
                              objectId: _vm.objectId,
                              attributes: {},
                              sharedSite: _vm.sharedSite,
                              globalId: _vm.gisInfoId
                            },
                            on: {
                              "public-link-created":
                                _vm.createSharedSiteIfNotExist,
                              "removed-sharing": _vm.createSharedSiteIfNotExist,
                              "site-name-saved": _vm.createSharedSiteIfNotExist
                            }
                          })
                        : _vm._e(),
                      _vm.selectedLayer.site_enabled
                        ? _c("v-divider")
                        : _vm._e(),
                      ["U"].includes(_vm.selectedLayerType)
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex-grow-1 overflow-y-auto background"
                            },
                            [
                              _vm.showGisInfoPanel
                                ? _c("SortedGisInfo", {
                                    attrs: {
                                      inRightPane: "",
                                      globalId: _vm.gisInfoId,
                                      selectedMapServiceId:
                                        _vm.selectedMapServiceId,
                                      objectId: _vm.objectId
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ]
          ),
          _c(
            "v-navigation-drawer",
            {
              staticStyle: { "z-index": "101" },
              attrs: {
                app: !_vm.$vuetify.breakpoint.xsOnly,
                right: "",
                permanent: _vm.showTasksTab,
                "hide-overlay": "",
                width: "375px",
                stateless: "",
                absolute: _vm.$vuetify.breakpoint.xsOnly
              },
              model: {
                value: _vm.showTasksTab,
                callback: function($$v) {
                  _vm.showTasksTab = $$v
                },
                expression: "showTasksTab"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "rounded-0 d-flex flex-column",
                  staticStyle: { height: "100vh" }
                },
                [
                  _c(
                    "v-list",
                    {
                      staticClass: "my-0 py-1",
                      attrs: { color: "#3F51B5", width: "100%" }
                    },
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            {
                              staticClass: "py-0 my-0",
                              attrs: { "two-line": "" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-space-between align-center"
                                },
                                [
                                  _c("div", { staticClass: "white--text" }, [
                                    _vm._v("Tasks")
                                  ]),
                                  _c(
                                    "v-btn",
                                    {
                                      staticStyle: { "font-size": "12px" },
                                      attrs: { icon: "", text: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.showTasksTab = false
                                          _vm.gisInfoTab = undefined
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "white" } },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.mdiClose) + " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.showTasksTab
                    ? [
                        ["U"].includes(_vm.selectedLayerType)
                          ? _c("TasksTab", {
                              staticClass: "overflow-y-auto",
                              attrs: {
                                globalId: _vm.gisInfoId,
                                selectedMapServiceId: _vm.selectedMapServiceId,
                                objectId: _vm.objectId
                              },
                              on: {
                                "update-task-count": function($event) {
                                  _vm.taskCount = $event
                                }
                              }
                            })
                          : _vm._e()
                      ]
                    : _vm._e()
                ],
                2
              )
            ]
          ),
          _c(
            "v-navigation-drawer",
            {
              staticStyle: { "z-index": "101" },
              attrs: {
                app: !_vm.$vuetify.breakpoint.xsOnly,
                right: "",
                permanent: _vm.showTicketLogTab,
                "hide-overlay": "",
                width: "375px",
                stateless: "",
                absolute: _vm.$vuetify.breakpoint.xsOnly
              },
              model: {
                value: _vm.showTicketLogTab,
                callback: function($$v) {
                  _vm.showTicketLogTab = $$v
                },
                expression: "showTicketLogTab"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "rounded-0 d-flex flex-column",
                  staticStyle: { height: "100vh" }
                },
                [
                  _c(
                    "v-list",
                    {
                      staticClass: "my-0 py-1",
                      attrs: { color: "#3F51B5", width: "100%" }
                    },
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            {
                              staticClass: "py-0 my-0",
                              attrs: { "two-line": "" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-space-between align-center"
                                },
                                [
                                  _c("div", { staticClass: "white--text" }, [
                                    _vm._v("Log")
                                  ]),
                                  _c(
                                    "v-btn",
                                    {
                                      staticStyle: { "font-size": "12px" },
                                      attrs: { icon: "", text: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.showTicketLogTab = false
                                          _vm.gisInfoTab = undefined
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "white" } },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.mdiClose) + " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.showTicketLogTab
                    ? [
                        ["U"].includes(_vm.selectedLayerType)
                          ? _c("Log", {
                              staticClass: "overflow-y-auto",
                              attrs: {
                                globalId: _vm.gisInfoId,
                                selectedMapServiceId: _vm.selectedMapServiceId,
                                objectId: _vm.objectId
                              }
                            })
                          : _vm._e()
                      ]
                    : _vm._e()
                ],
                2
              )
            ]
          ),
          _c(
            "v-navigation-drawer",
            {
              staticStyle: { "z-index": "101" },
              attrs: {
                app: !_vm.$vuetify.breakpoint.xsOnly,
                right: "",
                permanent: _vm.showDocsTab,
                "hide-overlay": "",
                width: "375px",
                stateless: "",
                absolute: _vm.$vuetify.breakpoint.xsOnly
              },
              model: {
                value: _vm.showDocsTab,
                callback: function($$v) {
                  _vm.showDocsTab = $$v
                },
                expression: "showDocsTab"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "rounded-0 d-flex flex-column",
                  staticStyle: { height: "100vh" }
                },
                [
                  _c(
                    "v-list",
                    {
                      staticClass: "my-0 py-1",
                      attrs: { color: "#3F51B5", width: "100%" }
                    },
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            {
                              staticClass: "py-0 my-0",
                              attrs: { "two-line": "" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-space-between align-center"
                                },
                                [
                                  _c("div", { staticClass: "white--text" }, [
                                    _vm._v("Documents")
                                  ]),
                                  _c(
                                    "v-btn",
                                    {
                                      staticStyle: { "font-size": "12px" },
                                      attrs: { icon: "", text: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.showDocsTab = false
                                          _vm.gisInfoTab = undefined
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "white" } },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.mdiClose) + " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.showDocsTab
                    ? [
                        ["U"].includes(_vm.selectedLayerType)
                          ? _c("DocsTab", {
                              staticClass: "overflow-y-auto",
                              attrs: {
                                globalId: _vm.gisInfoId,
                                selectedMapServiceId: _vm.selectedMapServiceId,
                                objectId: _vm.objectId
                              }
                            })
                          : _vm._e()
                      ]
                    : _vm._e()
                ],
                2
              )
            ]
          ),
          ["U"].includes(_vm.selectedLayerType)
            ? _c("ExpandInfoPanelDialog", {
                attrs: {
                  showExpansionPanelDialog: _vm.showExpansionPanelDialog,
                  globalId: _vm.gisInfoId,
                  selectedMapServiceId: _vm.selectedMapServiceId,
                  objectId: _vm.objectId,
                  selectedGisInfoObj: {}
                },
                on: {
                  "expand-info-panel-close": function($event) {
                    _vm.showExpansionPanelDialog = false
                  }
                }
              })
            : _vm._e(),
          _vm.$vuetify.breakpoint.xsOnly
            ? _c(
                "section",
                [
                  _c("MobileUploadTable", {
                    attrs: {
                      formSubmissions: _vm.mappedFormSubmissions,
                      userChoices: _vm.userChoices,
                      siteInfos: _vm.siteInfos,
                      mapServices: _vm.mapServices
                    },
                    on: {
                      "tickets-submitted": _vm.getSubmittedFormSubmissions,
                      "row-clicked": _vm.handleRowClick
                    }
                  })
                ],
                1
              )
            : _c(
                "section",
                { staticClass: "pt-3" },
                [
                  _c(
                    "div",
                    {
                      ref: "topToolbar",
                      staticClass:
                        "d-flex justify-space-between align-center px-5"
                    },
                    [
                      _c("div", { staticClass: "caption" }, [
                        _vm.isOnline
                          ? _c("span", [
                              _vm._v(
                                " The Form Submissions Shown Below Have Been Cleared And Are Ready To Be Uploaded. "
                              )
                            ])
                          : _c("span", { staticClass: "red--text" }, [
                              _vm._v(
                                " Device offline. Must be online to upload form submission. "
                              )
                            ])
                      ]),
                      _c(
                        "section",
                        { staticClass: "d-flex" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "button",
                              attrs: {
                                dark:
                                  _vm.isOnline &&
                                  _vm.unsubmittedFormSubmissions.length > 0 &&
                                  _vm.selected.length > 0,
                                color: "#3F51B5",
                                disabled:
                                  !_vm.isOnline ||
                                  _vm.unsubmittedFormSubmissions.length === 0 ||
                                  _vm.selected.length === 0
                              },
                              on: { click: _vm.submit }
                            },
                            [_vm._v(" Upload Form Submission ")]
                          ),
                          _c(
                            "v-menu",
                            {
                              attrs: { "offset-y": "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "px-1 ml-3",
                                              attrs: {
                                                text: "",
                                                color: "#3F51B5"
                                              }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-1",
                                              attrs: { dark: "" }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.mdiPlusCircle) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _vm._v(" Field ")
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-card-text",
                                    _vm._l(_vm.nonEmptyHeaders, function(h) {
                                      return _c("v-checkbox", {
                                        key: h.value,
                                        staticClass: "py-0 my-0",
                                        attrs: { label: h.text },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                          }
                                        },
                                        model: {
                                          value: _vm.headersEnabled[h.value],
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.headersEnabled,
                                              h.value,
                                              $$v
                                            )
                                          },
                                          expression: "headersEnabled[h.value]"
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  !_vm.loadingFormResults || !_vm.isOnline
                    ? _c("v-data-table", {
                        staticClass: "pa-0 ma-0 cursor-pointer",
                        attrs: {
                          headers: _vm.filteredHeaders,
                          items: _vm.mappedFormSubmissions,
                          "item-key": "formResultId",
                          "hide-default-footer": "",
                          "disable-pagination": "",
                          height: _vm.tableHeight,
                          "fixed-header": "",
                          loading: _vm.loading,
                          "show-select": ""
                        },
                        on: { "click:row": _vm.handleRowClick },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "no-data",
                              fn: function() {
                                return [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.isOnline
                                          ? "No data available"
                                          : "This device is offline. <u>Reload</u> the page when you are back online to load the Form Submissions"
                                      )
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "item.updatedOn",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatFullDate")(item.updatedOn)
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            },
                            {
                              key: "item.formTitle",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _vm._v(" " + _vm._s(item.formTitle) + " ")
                                ]
                              }
                            },
                            {
                              key: "item.refField",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _vm._v(
                                    " " + _vm._s(_vm.getSiteName(item)) + " "
                                  )
                                ]
                              }
                            },
                            {
                              key: "item.overallTasksStatus",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  item.overallTasksStatus ===
                                  _vm.FORM_RESULT_STATUS.COMPLETED_ERROR
                                    ? _c(
                                        "v-icon",
                                        { attrs: { color: "#FFAE42" } },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.mdiAlert) + " "
                                          )
                                        ]
                                      )
                                    : item.overallTasksStatus ===
                                      _vm.FORM_RESULT_STATUS.PROCESSING
                                    ? _c(
                                        "v-icon",
                                        { attrs: { color: "#00A3FF" } },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.mdiAutorenew) + " "
                                          )
                                        ]
                                      )
                                    : item.overallTasksStatus ===
                                      _vm.FORM_RESULT_STATUS.COMPLETED_SUCCESS
                                    ? _c(
                                        "v-icon",
                                        { attrs: { color: "green" } },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.mdiCheck) + " "
                                          )
                                        ]
                                      )
                                    : item.overallTasksStatus ===
                                        _vm.FORM_RESULT_STATUS
                                          .DEAD_LETTER_QUEUE ||
                                      item.overallTasksStatus ===
                                        _vm.FORM_RESULT_STATUS.ERROR
                                    ? _c(
                                        "v-icon",
                                        { attrs: { color: "red" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.mdiCloseCircleOutline
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              }
                            },
                            {
                              key: "item.mapServiceId",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.getMapServiceName(item)) +
                                      " "
                                  )
                                ]
                              }
                            },
                            {
                              key: "item.featureId",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _vm._v(" " + _vm._s(item.featureId) + " ")
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.selected,
                          callback: function($$v) {
                            _vm.selected = $$v
                          },
                          expression: "selected"
                        }
                      })
                    : _c(
                        "div",
                        { staticClass: "d-flex justify-center align-center" },
                        [
                          _c("v-progress-circular", {
                            attrs: { indeterminate: "", color: "#3F51B5" }
                          })
                        ],
                        1
                      )
                ],
                1
              ),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "600px" },
              model: {
                value: _vm.showSubmittingDialog,
                callback: function($$v) {
                  _vm.showSubmittingDialog = $$v
                },
                expression: "showSubmittingDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-center",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _vm._v(
                                    " Submitting " +
                                      _vm._s(_vm.numUnsubmittedTickets) +
                                      " of " +
                                      _vm._s(_vm.selected.length) +
                                      " Forms "
                                  )
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-center",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c("v-progress-circular", {
                                    attrs: {
                                      indeterminate: "",
                                      color: "#3F51B5"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "#673AB7", text: "" },
                          on: {
                            click: function($event) {
                              _vm.showSubmittingDialog = false
                            }
                          }
                        },
                        [_vm._v(" Cancel ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("AlreadyClearedDialog", {
            attrs: {
              showAlreadyClearedDialog: _vm.showAlreadyClearedDialog,
              ticketNumbersAlreadySubmitted: _vm.ticketNumbersAlreadySubmitted,
              formResultIdsAlreadySubmitted: _vm.formResultIdsAlreadySubmitted
            },
            on: {
              "delete-forms": function($event) {
                _vm.showAlreadyClearedDialog = false
              },
              "keep-forms": function($event) {
                _vm.showAlreadyClearedDialog = false
              }
            }
          }),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "600px", close: "" },
              model: {
                value: _vm.showErrorDialog,
                callback: function($$v) {
                  _vm.showErrorDialog = $$v
                },
                expression: "showErrorDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [_vm._v(" Error ")]),
                  _c("v-card-text", [_vm._v(" " + _vm._s(_vm.errorMsg) + " ")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-navigation-drawer",
            {
              style: { "z-index": _vm.$vuetify.breakpoint.xsOnly ? 10 : 1 },
              attrs: {
                app: !_vm.$vuetify.breakpoint.xsOnly,
                right: "",
                permanent: _vm.showNotificationPane,
                "hide-overlay": "",
                width: "375px",
                stateless: "",
                absolute: _vm.$vuetify.breakpoint.xsOnly
              },
              model: {
                value: _vm.showNotificationPane,
                callback: function($$v) {
                  _vm.showNotificationPane = $$v
                },
                expression: "showNotificationPane"
              }
            },
            [
              _vm.showNotificationPane
                ? _c("NotificationsPane", {
                    on: {
                      "close-notification-pane": function($event) {
                        _vm.showNotificationPane = false
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "600px",
                persistent: "",
                fullscreen: _vm.isFullScreen
              },
              model: {
                value: _vm.showEditFormDialog,
                callback: function($$v) {
                  _vm.showEditFormDialog = $$v
                },
                expression: "showEditFormDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _vm.showEditFormDialog
                    ? _c("DynamicForm", {
                        attrs: {
                          formDefinition: _vm.dynamicFormProps.formDefinition,
                          existingFormResultIdMap:
                            _vm.dynamicFormProps.existingFormResultIdMap,
                          selectedPdfFileUrl:
                            _vm.dynamicFormProps.selectedPdfFileUrl,
                          canEdit: _vm.dynamicFormProps.canEdit,
                          alreadySubmittedFinalOnline:
                            _vm.dynamicFormProps.alreadySubmittedFinalOnline,
                          globalId: _vm.dynamicFormProps.globalId,
                          objectId: _vm.dynamicFormProps.objectId,
                          selectedMapServiceId:
                            _vm.dynamicFormProps.selectedMapServiceId
                        },
                        on: {
                          "ticket-edit-form-close-button-click": function(
                            $event
                          ) {
                            _vm.showEditFormDialog = false
                          },
                          "ticket-edit-form-close": function($event) {
                            _vm.showEditFormDialog = false
                          },
                          "ticket-edit-form-submitted": function($event) {
                            _vm.showEditFormDialog = false
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm.showAuthExpiredDialog
            ? _c("AuthExpiredDialog", {
                attrs: { showAuthExpiredDialog: _vm.showAuthExpiredDialog },
                on: {
                  "portal-login-success": function($event) {
                    _vm.showAuthExpiredDialog = false
                  },
                  "auth-expired-dialog-close": function($event) {
                    _vm.showAuthExpiredDialog = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }